<template>
  <b-card-actions action-collapse title="Daftar Kendaraan">
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <b-form-group
          label="Sort"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
              class="w-75"
            >
              <template v-slot:first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          label="Filter"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          striped
          small
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(no)="row">
            <strong class="text-center">
              {{ row.index + 1 }}
            </strong>
          </template>
          <template #cell(tgl_stnk)="{item}">
              {{ humanDate(item.tgl_stnk) }}
          </template>
          <template #cell(tgl_kir)="{item}">
              {{ humanDate(item.tgl_kir) }}
          </template>
          <template #cell(tgl_pajak_th)="{item}">
              {{ humanDate(item.tgl_pajak_th) }}
          </template>

          <template #cell(actions)="row">
            <b-button
              v-b-tooltip.hover.right="'Riwayat'"
              size="sm"
              @click.prevent="$router.push(`/ceklist/riwayat/${row.item.id}`)"
              class="mr-1"
              variant="primary"
            >
            
              <feather-icon icon="CheckCircleIcon" />
            </b-button>
            <b-button
                  v-b-tooltip.hover.right="'Ubah'"
                  size="sm"
                  @click="edit(row.item)"
                  class="mr-1"
                  variant="info"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
          </template>
        </b-table>
      </b-col>
      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
    <b-modal
        v-model="showModalEdit"
          id="modal-ubah"
          cancel-variant="secondary"
          ok-title="Ubah"
          cancel-title="Batal"
          centered
          title="Form Ubah"
        >
          <b-form>
            <b-form-group>
              <label for="nama">Nama Kendaraan</label>
              <b-form-input v-model="form.nama_kendaraan" placeholder="Nama Kendaraan" />
            </b-form-group>
            <b-form-group>
              <label for="merk">Merk Kendaraan</label>
              <b-form-input v-model="form.merk_kendaraan" placeholder="Merk Kendaraan" />
            </b-form-group>
            <b-form-group>
              <label for="jenis">Jenis Kendaraan</label>
              <b-form-input v-model="form.jenis_kendaraan" placeholder="Jenis Kendaraan" />
            </b-form-group>
            <b-form-group>
              <label for="plat_nomer">Plat Nomor Kendaraan</label>
              <b-form-input v-model="form.plat_nomer" placeholder="Plat Nomor Kendaraan" />
            </b-form-group>
            <b-form-group>
              <label for="nama">Nomor STNK</label>
              <b-form-input v-model="form.no_stnk" placeholder="Nomor STNK" />
            </b-form-group>
            <b-form-group>
              <label for="nama">Tanggal STNK</label>
              <b-form-input type="date" v-model="form.tgl_stnk" placeholder="Tanggal Pepanjangan STNK" />
            </b-form-group>
            <b-form-group>
              <label for="nama">Nomor KIR</label>
              <b-form-input v-model="form.no_kir" placeholder="Nomor STNK" />
            </b-form-group>
            <b-form-group>
              <label for="nama">Tanggal Perpanjangan KIR</label>
              <b-form-input type="date" v-model="form.tgl_kir" placeholder="Tanggal Pepanjangan STNK" />
            </b-form-group>
            <b-form-group>
              <label for="nama">Tanggal Perpanjangan Pajak Tahunan </label>
              <b-form-input type="date" v-model="form.tgl_pajak_th" placeholder="Tanggal Pepanjangan STNK" />
            </b-form-group>
          </b-form>

          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button size="sm" class="mr-1" variant="info" @click="submit">
                Ubah
              </b-button>
              <b-button size="sm" class="mr-1" variant="primary" @click="resetForm">
                Hapus isian
              </b-button>
              <b-button size="sm" variant="danger" @click="showModalEdit = false">
                Keluar
              </b-button>
            </section>
          </template>
        </b-modal>
  </b-card-actions>
</template>

<script>
import Ripple from "vue-ripple-directive";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BTable,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  components: {
    BCardActions,
    ToastificationContent,
    VBTooltip,
    BModal,
    BDropdown,
    BDropdownItem,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BForm,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      showModalEdit: false,
      showModalTambah: false,
      id: null,
      form: {
        nama_kendaraan: "",
        merk_kendaraan: "",
        jenis_kendaraan: "",
        plat_nomer: "",
        tgl_sntk: null,
        no_stnk: null,
        tgl_kir:null,
        no_kir:null,
        tgl_pajak_th:null,
      },
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "no",
          label: "No",
        },
        // { key: "id", label: "ID"},
        { key: "nama_kendaraan", label: "Nama Kendaraan", sortable: true },
        { key: "merk_kendaraan", label: "Merk", sortable: true },
        { key: "jenis_kendaraan", label: "Jenis", sortable: true },
        { key: "plat_nomer", label: "Plat Nomor" },
        { key: "no_stnk", label: "STNK", sortable: true },
        { key: "tgl_stnk", label: "Tgl STNK", sortable: true },
        { key: "no_kir", label: "KIR", sortable: true },
        { key: "tgl_kir", label: "Tgl KIR", sortable: true },
        { key: "tgl_pajak_th", label: "Tgl Pajak Tahunan", sortable: true },
        // { key: "sales_id", label: "Sales"},
        // { key: "delete", label: "Hapus"},
        { key: "actions", label: "Aksi" },
      ],
      items: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items
    this.getData();
  },
  methods: {
    edit(item) {
      this.id = item.id
      this.form = item
      this.showModalEdit = true
    },
    add() {
      this.id = null;
      this.activeAction = "tambah";
      this.showModalTambah = true;
      this.showModalEdit = false;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data kendaraan ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("kendaraan/save", [item])
            .then(() => {
              this.getData();
              this.pesanBerhasilHapus();
            })
            .catch((e) => {
              this.pesanGagalHapus();
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
      this.form = {
        nama_kendaraan: "",
        merk_kendaraan: "",
        jenis_kendaraan: "",
        plat_nomer: "",
      };
    },
    submit() {
      if (this.form.nama_kendaraan == null || this.form.nama_kendaraan == "") {
        this.pesanGagalSimpan();
        return false;
      }
      if (this.id) {
        this.form.id = this.id;
      }
      const payload = this.form;
      this.$store
        .dispatch("kendaraan/save", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.getData();
          this.pesanBerhasilSimpan();
          this.resetForm();
          this.showModalTambah = false;
          this.showModalEdit = false;
        })
        .catch((e) => console.error(e));
      // this.pesanGagalSimpan()
    },
    // edit(item) {
    //   this.id = item.id;
    //   this.form = item;
    //   this.showModalEdit = true;
    // },
    pesanBerhasilSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Berhasil menyimpan data",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanBerhasilHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Berhasil menghapus data",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menghapus data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    getData() {
      this.$store
        .dispatch("kendaraan/getData", {})
        .then(() => {
          this.items = this.$store.state.kendaraan.datas;
          this.totalRows = this.items.length;
        })
        .catch((e) => console.error(e));
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
